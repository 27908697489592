import { Card } from 'react-bootstrap';
import {
  useExecuteTaskTransitionMutation,
  useLoadTaskDetailQuery,
  useLoadTaskTransitionsQuery,
} from '@store/tasks/tracker.slice';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '@hooks/index';
import { StatusLabel, statusTaskColors } from '@components/ui/StatusLabel/StatusLabel';
import { Button, Skeleton } from '@mui/material';
import { ReactComponent as EditIconGreen } from '@assets/img/editIconGreen.svg';
import { Star } from '@components/Star/Star';
import { setTasksFavorite } from '@store/tasks/tracker.service';
import { TaskTransitions } from '@pages/task/TaskTransitions';
import { TaskTransition, TaskTransitionsField } from '@store/tasks/contracts';
import { ReactComponent as AddIcon } from '@assets/img/addGray.svg';
import { closeModal, openModal } from '@store/ui/ui.slice';
import { AddTimeModal } from './AddTimeModal/AddTimeModal';
import { SpentTimeModal } from './SpentTimeModal/SpentTimeModal';
import styles from './TaskPageDetail.module.scss';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import objectSupport from 'dayjs/plugin/objectSupport';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ru';
import { Transition, TransitionValue } from '@components/Transition/Transition';
import { JsonSchema7 } from '@jsonforms/core';
import { MarkdownPreviewComponent } from '@components/ui/MarkdownEditor/components/MarkdownPreview';
import { withSuspense } from 'src/hocs/withSuspense';

require('dayjs/locale/ru');

dayjs.extend(objectSupport);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);
// dayjs

const MarkdownPreview = withSuspense(MarkdownPreviewComponent);

const DetailBlock = ({
  label,
  value,
}: {
  label: string;
  value: string | undefined | JSX.Element;
}) => {
  return (
    <div className="mb-3">
      {value !== undefined ? (
        <>
          <div className="color-gray fs-6 fw-normal">{label}</div>
          <div className="fs-6 fw-500">{value}</div>
        </>
      ) : (
        <>
          <Skeleton />
        </>
      )}
    </div>
  );
};

export const TaskPageDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { taskId } = useParams();
  const [makeTransitionTask] = useExecuteTaskTransitionMutation();
  const { data: task } = useLoadTaskDetailQuery(taskId!);
  const { data: taskTransitions } = useLoadTaskTransitionsQuery(taskId!);

  const handleMakeTransition = useCallback(
    async (form: TransitionValue) => {
      const body = {
        ...form,
      };
      await makeTransitionTask({
        taskId: taskId!,
        transition: body,
      })
        .unwrap()
        .then(() => {
          dispatch(closeModal());
        })
        .catch((_) => {});
    },
    [makeTransitionTask],
  );

  const openTransitionTaskModal = useCallback(
    (fields: TaskTransitionsField[], transitionId: string, schema: JsonSchema7) => {
      dispatch(
        openModal(
          <Transition
            onSubmit={handleMakeTransition}
            fields={fields}
            schema={schema}
            transitionId={transitionId}
          />,
        ),
      );
    },
    [dispatch],
  );

  const handleTransition = useCallback((transition: TaskTransition) => {
    if (transition.fields && transition.fields.length > 0 && transition.schema) {
      openTransitionTaskModal(transition.fields, transition.id!, transition.schema);
    } else {
      makeTransitionTask({
        taskId: taskId!,
        transition: {
          transitionId: transition.id!,
        },
      });
    }
  }, []);

  const taskEstimation = useMemo(() => {
    if (typeof task?.estimation === 'string' && task?.estimation !== 'P0D') {
      return task?.estimation;
    }
    return '';
  }, [task?.estimation]);

  if (!task) {
    return <Skeleton />;
  }

  return (
    <Card className="py-4 px-5 form-container mb-5">
      <div className="row justify-content-between">
        <div className="col-6">
          <div className="mb-2">
            <h2 className="m-0 me-1">
              <span>{task.key}</span> - <span>{task.name}</span>
              {taskId ? (
                <Star
                  checked={task.isFavorite}
                  onChange={() => setTasksFavorite(taskId)}
                  style={{ marginLeft: '0.5rem' }}
                />
              ) : (
                <></>
              )}
            </h2>
          </div>
          {task ? (
            <StatusLabel
              className="m-0"
              text={task.status?.display}
              color={statusTaskColors.get(task?.statusType)}
            />
          ) : (
            <Skeleton />
          )}
          <div className="mt-3">
            <TaskTransitions transitions={taskTransitions ?? []} onChange={handleTransition} />
          </div>
        </div>
        <div className="col-auto">
          <Button variant="outlined" startIcon={<EditIconGreen />} onClick={() => navigate('edit')}>
            Редактировать
          </Button>
        </div>
      </div>
      <div className="row gx-5">
        <div className="col">
          <div className="mt-4 mb-3 gap-4">
            <p className="color-gray">Описание задачи</p>
            <MarkdownPreview value={task.description} />
          </div>
        </div>
        <div className="col">
          <Card className="py-4 px-5 mb-5">
            <DetailBlock label="Название проекта" value={task.projectName} />
            <DetailBlock label="Тип задачи" value={task.type?.display} />
            <DetailBlock label="Дата начала" value={task.created.format('DD.MM.YYYY')} />
            <DetailBlock
              label="Дедлайн задачи"
              value={task.deadline ? task?.deadline.format('DD.MM.YYYY') : '-'}
            />
            <DetailBlock
              label="Оценка времени"
              value={`${
                taskEstimation.length > 0
                  ? dayjs.duration(taskEstimation).format('D[д] H[ч] m[м]')
                  : '-'
              }`}
            />
            <DetailBlock
              label="Учет времени"
              value={
                <div>
                  <button
                    className={styles.spent__time__value}
                    onClick={() => dispatch(openModal(<SpentTimeModal taskId={taskId} />))}>
                    60 минут
                  </button>
                  <button onClick={() => dispatch(openModal(<AddTimeModal />))}>
                    <AddIcon />
                  </button>
                </div>
              }
            />
            <DetailBlock label="Теги" value={task.tags?.join(', ')} />
            <DetailBlock label="Приоритет" value={task.priority?.display} />
          </Card>
        </div>
      </div>
    </Card>
  );
};
